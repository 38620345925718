import { BrowserRouter as Router } from 'react-router-dom';
import MainRouter from '@routes/MainRouter';
import '@styles/globals.scss';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: React.FC = () => {
  return (
    <Router>
      <MainRouter />
    </Router>
  );
};

export default App;
