export const validatePassword = async (password: string): Promise<string> => {
  const regex = new RegExp(
    '^(?=[a-zA-Z0-9._@!#$%^&*]{6,18}$)(?!.*[_.]{2})[^_.].*[^_.]$',
  );

  if (!regex.test(password)) {
    throw new Error(
      'Mật khẩu phải từ 6 đến 18 ký tự và không chứa các ký tự đặc biệt!',
    );
  }

  return '';
};

export const validateUsername = async (username: string): Promise<string> => {
  const regex = new RegExp(
    '^(?=[a-zA-Z0-9._]{3,30}$)(?!.*[_.]{2})[^_.].*[^_.]$',
  );

  if (!regex.test(username)) {
    throw new Error(
      'Tên đăng nhập phải từ 3 đến 30 ký tự và không chứa các ký tự đặc biệt!',
    );
  }

  return '';
};

export const validateIdentificationNumber = (_: any, value: any) => {
  // console.log(value, value.length);
  if (!value) {
    return Promise.reject('Vui lòng nhập giá trị');
  }
  if (isNaN(value)) {
    return Promise.reject('Căn cước công dân là số');
  }
  if (value.toString().length !== 12) {
    return Promise.reject('Căn cước công dân phải có chính xác 12 số');
  }
  if (value.toString().includes(' ')) {
    return Promise.reject('Không được chứa khoảng trắng');
  }
  return Promise.resolve();
};

export const validatePhoneNumber = (_: any, value: any) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập SĐT');
  }
  if (isNaN(value)) {
    return Promise.reject('SĐT phải là số');
  }
  if (value.includes(' ')) {
    return Promise.reject('K được có dấu cách');
  }
  if (!/^0/.test(value)) {
    return Promise.reject('SĐT phải bđ là 0');
  }
  if (value.length !== 10) {
    return Promise.reject('SĐT phải có 10 số');
  }
  return Promise.resolve();
};

export const validateEmail = (_: any, value: any) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập địa chỉ email');
  }
  if (value.includes(' ')) {
    return Promise.reject('Không được chứa khoảng trắng');
  }
  // Sử dụng biểu thức chính quy để kiểm tra định dạng email
  const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
  if (!emailPattern.test(value)) {
    return Promise.reject('Định dạng email không hợp lệ');
  }
  return Promise.resolve();
};

export const validatePasswordRegitser = (_: any, value: any) => {
  if (!value) {
    return Promise.reject('Vui lòng nhập mật khẩu');
  }
  if (value.length < 6) {
    return Promise.reject('Mật khẩu không được ít hơn 6 ký tự');
  }
  return Promise.resolve();
};
