import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { UserOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { handleLogin } from '@services';
import { ILoginForm } from '@utils/constant/form.interface';
import { getStatusText } from '@utils/constant/StatusCode';
import { ErrorData } from '@utils/Response/Error';
import { saveTokenToCookie } from '@utils/Functions/Token';
import {
  saveToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '@utils/Functions/Storage';

const LoginForm = () => {
  const [initialValues, setInitialValues] = useState({
    username: '',
    password: '',
    remember: false,
  });
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const rejectParam = urlParams.get('redirect');
  useEffect(() => {
    // lấy thông tin đăng nhập đã đc lưu từ trước
    const usernameLocal = getFromLocalStorage('username');
    const passwordLocal = getFromLocalStorage('password');
    const rememberLocal = getFromLocalStorage('remember');
    setInitialValues({
      username: usernameLocal,
      password: passwordLocal,
      remember: rememberLocal ? true : false,
    });
  }, []);
  const onFinish = async (values: any) => {
    console.log(values, 'values');
    const body = {
      username: values.username,
      password: values.password,
    };
    if (values?.remember) {
      // tiến hành lưu thông tin đăng nhập cho lần sau
      saveToLocalStorage('username', values.username);
      saveToLocalStorage('password', values.password);
      saveToLocalStorage('remember', values.remember);
    } else {
      removeFromLocalStorage('username');
      removeFromLocalStorage('password');
      removeFromLocalStorage('remember');
    }
    handleLogin
      .Login(body)
      .then((res: any) => {
        const { statusCode, data, message } = res;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thành công đăng nhập vào hệ thống quản trị FacialBar',
          });
          saveTokenToCookie(data.accessToken, 'accessToken');
          saveTokenToCookie(data.refreshToken, 'refreshToken');
          setTimeout(() => {
            if (rejectParam) {
              window.location.href = rejectParam;
            } else {
              // 5. Chuyển đến domain localhost:3001
              const domainBase = process.env.REACT_APP_BASE_DOMAIN;
              if (domainBase) {
                window.location.href = domainBase;
              }
            }
          }, 300);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Tài khoản hoặc mật khẩu không chính xác',
          });
        }
      })
      .catch((error: ErrorData) => {
        console.log(error, 'error');
        notification.error({
          message: 'Lỗi',
          description: 'Tài khoản hoặc mật khẩu không chính xác',
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="sm:text-3xl max-sm:text-xl font-bold text-[#032D23]">
        Đăng Nhập
      </div>
      <Form
        name="basic"
        key={JSON.stringify(initialValues)}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="w-full mt-5"
      >
        <Form.Item<ILoginForm>
          label=""
          name="username"
          rules={[{ required: true, message: 'Vui lòng nhập tên tài khoản!' }]}
        >
          <Input
            placeholder="Nhập tên tài khoản"
            className="w-full h-[40px] pl-3"
            prefix={<UserOutlined className="mr-2 site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item<ILoginForm>
          label=""
          name="password"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
        >
          <Input.Password
            placeholder="Nhập mật khẩu"
            className="w-full h-[40px] pl-3"
            prefix={<KeyOutlined className="mr-2 site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item name="" valuePropName="checked" className="">
          <div className="flex justify-between">
            <Form.Item name="remember" valuePropName="checked" className="mb-0">
              <Checkbox>Nhớ mật khẩu</Checkbox>
            </Form.Item>
            <Link to={'/forgot-password'} className="italic">
              Quên mật khẩu
            </Link>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="w-full"
          >
            ĐĂNG NHẬP
          </Button>
        </Form.Item>
        <Link to="/app-staff" className="text-[#1677FF] text-center">
          Tải ứng dụng nhân viên
        </Link>
      </Form>
    </>
  );
};

export default LoginForm;
