import React from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link
      to="/"
      className="flex items-center justify-between w-full sm:mt-8 sm:px-20 max-sm:mt-5"
    >
      <img
        src="/logo.png"
        alt="weblue"
        className="sm:h-[48px] max-sm:h-[30px] text-center max-sm:mx-[auto]"
      />
      <ShoppingCartOutlined className="text-[40px] max-sm:hidden" />
    </Link>
  );
};

export default Logo;
