// DashboardLayout.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  DownOutlined,
  LoginOutlined,
  TeamOutlined,
  ReadOutlined,
  BankOutlined,
  UserOutlined,
  DatabaseOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Dropdown, notification, ConfigProvider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import './index.scss';
import Cookies from 'js-cookie';
import SliderComponent from '@components/Slide';
interface LoginLayoutProps {
  children: React.ReactNode;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6D8A63',
          colorLink: '#6D8A63',
          colorInfo: '#6D8A63',
          colorInfoActive: '#6D8A63',
          borderRadius: 2,
        },
      }}
    >
      <div className="w-[100vw] h-[100vh] flex overflow-hidden">
        <div className="lg:w-[50%] max-lg:w-full">{children}</div>
        <div className="lg:w-[50%] max-lg:hidden">
          <SliderComponent />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default LoginLayout;
