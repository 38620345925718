import React, { useState } from 'react';
import Logo from '@components/Logo';
import LoginForm from '@components/Form/Login';
import LoginQRForm from '@components/Form/LoginQR';
import { Link } from 'react-router-dom';

const ListApp = () => {
  const [typeLogin, setTypeLogin] = useState<number>(2);
  return (
    <div>
      <div className="flex flex-col items-center w-full sm:mt-5">
        <Logo />
        <div className="w-full py-5 bg-white rounded-lg sm:mt-5 sm:px-20 max-sm:px-[3%]">
          {/* <div className="text-[#032D23] font-semibold sm:text-2xl max-sm:text-xl max-sm:text-center">
            Hệ thống quản trị FACIAL BAR
          </div> */}
        </div>
      </div>
      <div className="">
        <div className="text-[#032D23] font-semibold sm:text-2xl max-sm:text-xl text-center mb-8">
          Tải ứng dụng Facial Bar cho Android
        </div>
        <div className="items-center justify-center sm:flex">
          <Link
            to="https://facialbar.vn/app/checkin-facialbar.apk"
            className="text-center sm:mr-16"
          >
            <img
              src="/icon/checkin.jpg"
              className="w-[200px] h-[200px] rounded-[15px] max-sm:mx-auto"
            />
            <div className="w-[200px] text-center mt-4 text-[#1677FF] max-sm:mx-auto max-sm:mb-5">
              Ứng dụng dành cho Trạm trưởng
            </div>
          </Link>
          <Link
            to="https://facialbar.vn/app/ktv-facialbar.apk"
            className="sm:ml-16 max-sm:mb-10"
          >
            <img
              src="/icon/ktv.jpg"
              className="w-[200px] h-[200px] rounded-[15px] max-sm:mx-auto"
            />
            <div className="w-[200px] text-center mt-4 text-[#1677FF] max-sm:mx-auto">
              Ứng dụng dành cho Kỹ thuật viên
            </div>
          </Link>
        </div>
      </div>
      <div className="absolute sm:bottom-5 max-sm:bottom-10 lg:flex items-center justify-between lg:w-[50vw] lg:px-20 max-lg:text-center max-lg:w-full max-lg:text-[13px]">
        <div>
          <div>© 2024 CÔNG TY CÔ PHẦN FACIAL BAR VIỆT NAM.</div>
        </div>
        {/* <div>Terms & Conditions</div> */}
      </div>
    </div>
  );
};

export default ListApp;
