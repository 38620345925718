const statusTextMap: Record<number, string> = {
  100: 'Đăng nhập thành công',
  101: 'Không tồn tại mã phòng khám',
  102: 'Tài khoản không đúng',
  103: 'Mật khẩu không đúng',

  105: 'Tạo tài khoản thành công',
};

// Hàm để lấy văn bản tương ứng với status code
export const getStatusText = (
  statusCode: keyof typeof statusTextMap,
): string => {
  return (
    statusTextMap[statusCode] || 'Có lỗi xảy ra trong quá trình xử lý dữ liệu'
  );
};
