import * as loginEndpoint from '@api/login';
import { ErrorResponse, ErrorData } from '@utils/Response/Error';
import { LoginDto, ForwardPassWordDto } from '@utils/constant/login.dto';
import { BaseResponse } from '@utils/Response/Base';
import { LoginResponse } from '@utils/Response/Login';
export const Login = async (
  body: LoginDto,
): Promise<BaseResponse<LoginResponse> | ErrorData> => {
  try {
    const res = await loginEndpoint.login(body);
    const { status, response, data, message } = res;
    if (status === 200) {
      return data;
    } else {
      throw new Error(data);
    }
  } catch (error: any) {
    const newError: ErrorData = error.response?.data;
    throw newError;
  }
};

export const forwordPassword = async (
  body: ForwardPassWordDto,
): Promise<any | Error> => {
  try {
    const response = await loginEndpoint.forwardPassword(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const SendSMS = async (body: any): Promise<any | Error> => {
  try {
    const response = await loginEndpoint.sendSMS(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const VerifyOTP = async (body: any): Promise<any | Error> => {
  try {
    const response = await loginEndpoint.verifyOTP(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const ChangePassword = async (
  body: any,
  token: string,
): Promise<any | Error> => {
  try {
    const response = await loginEndpoint.changePassword(body, token);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
