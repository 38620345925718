export const saveToLocalStorage = (title: any, value: any) => {
  try {
    localStorage.setItem(title, JSON.stringify(value));
    console.log(
      `Dữ liệu đã được lưu trữ trong local storage với title là '${title}'.`,
    );
  } catch (error) {
    console.error('Lỗi khi lưu dữ liệu vào local storage:', error);
  }
};

export const getFromLocalStorage = (title: any) => {
  try {
    const storedData = localStorage.getItem(title);
    if (storedData === null) {
      console.log(
        `Không tìm thấy dữ liệu trong local storage với title là '${title}'.`,
      );
      return null;
    }
    return JSON.parse(storedData);
  } catch (error) {
    console.error('Lỗi khi lấy dữ liệu từ local storage:', error);
    return null;
  }
};

export const removeFromLocalStorage = (title: any) => {
  try {
    localStorage.removeItem(title);
    console.log(`Đã xoá dữ liệu trong local storage với title là '${title}'.`);
  } catch (error) {
    console.error('Lỗi khi xoá dữ liệu từ local storage:', error);
  }
};
