import React from 'react';
import Slider from 'react-slick';

const SliderComponent = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 900,
    autoplaySpeed: 3000,
    cssEase: 'linear',
  };
  return (
    <div>
      <Slider {...settings}>
        <div>
          <div
            style={{
              backgroundImage: `url('/slide/slide1.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'top left',
              height: '100vh',
              width: '100%',
            }}
          ></div>
        </div>
        <div>
          <div
            style={{
              backgroundImage: `url('/slide/slide2.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'top left',
              height: '100vh',
              width: '100%',
            }}
          ></div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderComponent;
