import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { MailOutlined, LockOutlined, KeyOutlined } from '@ant-design/icons';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { handleLogin } from '@services';
import { ILoginForm } from '@utils/constant/form.interface';
import { getStatusText } from '@utils/constant/StatusCode';
import { ErrorData } from '@utils/Response/Error';
import { saveTokenToCookie } from '@utils/Functions/Token';
import OtpInput from 'react-otp-input';
import { validatePasswordRegitser } from '../../utils/Functions/validate';

const OTPEmailForm = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const rejectParam = urlParams.get('redirect');
  const { id } = useParams();
  const [otp, setOtp] = useState('');
  const [token, setToken] = useState('');
  const [changePass, setChangePass] = useState(false);
  console.log(rejectParam, 'rejectParam');
  const onFinish = async (values: any) => {
    const body = {
      newPassword: values.newPassword,
      confirmationPassword: values.confirmationPassword,
    };
    console.log(otp, 'otp');
    setChangePass(true);
    handleLogin
      .ChangePassword(body, token)
      .then((res: any) => {
        const { statusCode, data, message } = res;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi mật khẩu tài khoản thành công',
          });
          navigate(`/`);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error: ErrorData) => {
        console.log(error, 'error');
        notification.error({
          message: 'Lỗi',
          description: getStatusText(error.statusCode),
        });
      });
  };
  const handleSendOTP = async () => {
    const body = {
      otp: otp,
      phoneNumberOrUser: id,
    };
    console.log(body, 'body');
    handleLogin
      .VerifyOTP(body)
      .then((res: any) => {
        const { statusCode, data, message } = res;
        if (statusCode === 200) {
          setToken(data.accessToken);
          setChangePass(true);
        } else {
          throw new Error('Invalid status code: ' + statusCode);
        }
      })
      .catch((error: ErrorData) => {
        console.log(error, 'error');
        notification.error({
          message: 'Lỗi',
          description: 'Mã OTP không đúng, vui lòng thử lại',
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      {changePass ? (
        <>
          <div className="sm:text-3xl max-sm:text-xl font-bold text-[#032D23]">
            Đổi mật khẩu
          </div>
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            className="w-full mt-5"
          >
            <div className="mb-5">Vui lòng đặt mật khẩu mới</div>

            <Form.Item
              label=""
              name="newPassword"
              rules={[{ required: true, validator: validatePasswordRegitser }]}
            >
              <Input.Password
                placeholder="Nhập mật khẩu mới"
                className="w-full h-[40px] pl-3"
                prefix={<KeyOutlined className="mr-2 site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item
              label=""
              name="confirmationPassword"
              rules={[{ required: true, validator: validatePasswordRegitser }]}
            >
              <Input.Password
                placeholder="Nhập lại mật khẩu mới"
                className="w-full h-[40px] pl-3"
                prefix={<KeyOutlined className="mr-2 site-form-item-icon" />}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="w-full"
              >
                XÁC NHẬN
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <div className="sm:text-3xl max-sm:text-xl font-bold text-[#032D23]">
            Nhập mã OTP
          </div>
          <Form
            name="basic"
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="w-full mt-5"
          >
            <div className="mb-5">
              Chúng tôi đã gửi mã OTP 6 số tới địa chỉ <strong>{id}</strong>.
              Vui lòng nhập 6 số để tiếp tục đổi mật khẩu:
            </div>

            <Form.Item<ILoginForm>
              label=""
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mã OTP!' }]}
            >
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="border-[1px] ml-3 mr-3 rounded-[8px]"
                    style={{
                      width: '35px',
                      height: '60px',
                      fontSize: '22px',
                      textAlign: 'center',
                    }}
                  />
                )}
              />
            </Form.Item>

            <Form.Item<ILoginForm> className="">
              <div className="flex justify-between">
                <Link to={'/'} className="italic">
                  Quay lại trang đăng nhập
                </Link>
              </div>
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                type="primary"
                onClick={handleSendOTP}
                className="w-full"
              >
                TIẾP TỤC
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

export default OTPEmailForm;
