import Cookies from 'js-cookie';

export const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken');
};

// Hàm để lưu token vào cookie với path và domain là localhost
export const saveTokenToCookie = (token: string, type: string) => {
  // Thời gian sống của cookie (ở đây là 1 giờ, bạn có thể điều chỉnh theo nhu cầu của mình)
  const expirationTime = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  // Domain của website
  const myDomain = process.env.REACT_APP_BASE_SERVER;
  // Format cookie string
  const cookieString = `${type}=${token}; expires=${expirationTime.toUTCString()}; path=/; domain=${myDomain}`;

  // Lưu cookie
  document.cookie = cookieString;
};
