// MainRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '@pages/Login';
import ForgotPassword from '@pages/ForgotPassword';
import LoginLayout from '@layouts/LoginLayout';
import OTPEmailForm from '@pages/OTPEmail';
import ListApp from '@pages/App';
import Page404 from '@pages/Errors/404';
import Page403 from '@pages/Errors/403';
import Page500 from '@pages/Errors/500';

const MainRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <LoginLayout>
              <Login />
            </LoginLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <LoginLayout>
              <ForgotPassword />
            </LoginLayout>
          }
        />
        <Route
          path="/send-email/:id"
          element={
            <LoginLayout>
              <OTPEmailForm />
            </LoginLayout>
          }
        />
        <Route path="/app-staff" element={<ListApp />} />
        <Route path="/403" element={<Page403 />} />

        <Route path="/500" element={<Page500 />} />

        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default MainRouter;
