import React, { useState } from 'react';
import Logo from '@components/Logo';
import LoginForm from '@components/Form/Login';
import LoginQRForm from '@components/Form/LoginQR';

const Login = () => {
  const [typeLogin, setTypeLogin] = useState<number>(2);
  return (
    <div>
      <div className="flex flex-col items-center w-full sm:mt-5">
        <Logo />
        <div className="w-full py-5 bg-white rounded-lg sm:mt-5 sm:px-20 max-sm:px-[3%]">
          <div className="text-[#032D23] font-semibold sm:text-2xl max-sm:text-xl sm:mb-20 max-sm:text-center max-sm:mb-5">
            Hệ thống quản trị FACIAL BAR
          </div>
          <LoginForm />
        </div>
      </div>
      <div className="absolute sm:bottom-5 max-sm:bottom-10 lg:flex items-center justify-between lg:w-[50vw] lg:px-20 max-lg:text-center max-lg:w-full max-lg:text-[13px]">
        <div>© 2024 CÔNG TY CÔ PHẦN FACIAL BAR VIỆT NAM.</div>
        {/* <div>Terms & Conditions</div> */}
      </div>
    </div>
  );
};

export default Login;
