import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, notification } from 'antd';
import { MailOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { handleLogin } from '@services';
import { getStatusText } from '@utils/constant/StatusCode';
import { ErrorData } from '@utils/Response/Error';
import { saveTokenToCookie } from '@utils/Functions/Token';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const rejectParam = urlParams.get('redirect');
  console.log(rejectParam, 'rejectParam');
  const onFinish = async (values: any) => {
    setLoading(true);
    const body = {
      phoneOrUsername: values.email,
    };

    handleLogin
      .SendSMS(body)
      .then((res: any) => {
        const { statusCode, data, message } = res;
        if (statusCode === 200) {
          setLoading(false);
          navigate(`/send-email/${encodeURIComponent(values.email)}`);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error: ErrorData) => {
        console.log(error, 'error');
        setLoading(false);
        notification.error({
          message: 'Lỗi',
          description: 'Tên tài khoản hoặc số điện thoại không chính xác',
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <div className="sm:text-3xl max-sm:text-xl font-bold text-[#032D23]">
        Quên mật khẩu
      </div>
      <Form
        name="basic"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="w-full mt-5"
      >
        <div className="mb-5">
          Vui lòng nhập tên tài khoản hoặc số điện thoại
        </div>

        <Form.Item
          label=""
          name="email"
          rules={[
            { required: true, message: 'Vui lòng nhập sđt hoặc tên tài khoản' },
          ]}
        >
          <Input
            placeholder="Nhập tên tài khoản/ SĐT"
            className="w-full h-[40px] pl-3"
            prefix={<LockOutlined className="mr-2 site-form-item-icon" />}
          />
        </Form.Item>

        <Form.Item>
          <div className="flex justify-between">
            <Link to={'/'} className="italic">
              Quay lại trang đăng nhập
            </Link>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type={loading ? 'default' : 'primary'}
            htmlType="submit"
            className="w-full"
            disabled={loading}
          >
            {loading ? (
              <LoadingOutlined className="relative top-[-2px]" />
            ) : (
              'TIẾP TỤC'
            )}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ForgotPasswordForm;
